.appointment_main .top_sec {
  background: #1b9084 !important;
  padding: 3%;
}

.appointment_main .top_sec .img_hW {
  max-width: 900px;
}

.appointment_main h1 {
  color: #000000;
  font-size: 46px;
  line-height: 1.2em;
  margin: 0 0 30px;
  font-weight: 700;
  font-family: "proxima-nova", sans-serif;
}

.appointment_main h3 {
  color: #696969;
  font-size: 28px;
  line-height: 1.2em;
  font-weight: 700;
  font-family: "proxima-nova", sans-serif;
}

.appointment_main h4 {
  color: #696969;
  font-weight: 700;
  font-family: "proxima-nova", sans-serif;
}

.appointment_main p {
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #696969;
}

.apointment_taking_content {
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 8%);
  background: white;
  border: 2px solid rgba(105, 105, 105, 0.1);
  /* max-height: 750px; */
  overflow: auto;
  transition: all 0.22s ease-out;
}

.apointment_taking_content svg {
  color: #696969;
  font-size: 20px;
}

.appointment_main .apointment_taking_content p {
  font-family: "proxima-nova", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #696969;
}

.appointment_main .apointment_taking_content .border_padding {
  padding: 3%;
}

.appointment_main .apointment_taking_content .border_padding:first-child {
  border-right: 2px solid rgba(105, 105, 105, 0.1);
}

/* react calender */
.appointment_main .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: unset;
}

.appointment_main .react-calendar {
  width: 100%;
}

.appointment_main .react-calendar__tile:disabled {
  background-color: unset;
  pointer-events: none !important;
}

.appointment_main .react-calendar__navigation button:disabled {
  background-color: unset;
}

.appointment_main .react-calendar__tile.react-calendar__month-view__days__day {
  height: 45px !important;
  border-radius: 50%;
  font-size: 16px;
  /* background: #F2FAF9; */
}

.appointment_main .react-calendar__tile:enabled:hover,
.appointment_main .react-calendar__tile:enabled:focus {
  background-color: #af7a6d;
  color: white;
}

.appointment_main .react-calendar__tile--active {
  background-color: #653239 !important;
  color: white !important;
}

.appointment_main .react-calendar__tile--now {
  background: unset;
}

.appointment_main .react-calendar__navigation {
  position: relative;
}

.appointment_main .react-calendar__navigation button {
  min-width: unset;
  background: none;
}

.appointment_main
  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 2em !important;
  position: absolute;
  right: 2em;
  top: 13px;
}

.appointment_main
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 2em !important;
  position: absolute;
  top: 13px;
  right: 20px;
}

/* .apointment_confim_shedule {
    max-height: 550px;
    overflow-y: scroll;
} */

.apointment_confim_shedule {
  max-height: 370px;
  overflow-y: scroll;
}

/* apointment button css */
.apointment_confim_shedule button {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 52px;
  border: 1px solid rgb(6, 148, 130);
  background: #fff;
  color: rgb(6, 148, 130);
  font-weight: 700;
  box-shadow: none !important;
  font-size: 14px !important;
}

.apointment_confim_shedule .main_btn.btn-primary:hover {
  background: #fff;
  color: rgb(6, 148, 130);
  border-width: 2px !important;
  border-color: rgb(6, 148, 130);
}

.apointment_confim_shedule .confirm_time.btn-primary {
  background-color: rgb(0 0 0/60%);
  color: #fff;
  border-color: transparent;
  pointer-events: none;
}

.apointment_confim_shedule .confirm_btn.btn-primary {
  background-color: rgb(6, 148, 130);
  color: #fff;
}

.apointment_confim_shedule .confirm_btn.btn-primary:hover {
  background-color: rgb(6, 148, 130) !important;
  color: #fff !important;
  border: 1px solid rgb(6, 148, 130);
}

.apointment_confim_shedule .main_btn.btn-check:focus + .btn-primary,
.main_btn.btn-primary:focus {
  background: #fff !important;
  color: rgb(6, 148, 130) !important;
  border: 1px solid rgb(6, 148, 130);
}

.apointment_confim_shedule .confirm_btn.btn-check:focus + .btn-primary,
.confirm_btn.btn-primary:focus {
  background-color: rgb(6, 148, 130);
  color: #fff;
  border: 1px solid rgb(6, 148, 130);
}

.logo_wrapper {
  padding: 22px 22px 30px;
  border-bottom: 2px solid rgba(105, 105, 105, 0.1);
}

.logo_icon_wrapper {
  padding: 12px 0;
}

@media (min-width: 767px) and (max-width: 1000px) {
  .appointment_main h1 {
    font-size: 40px;
    line-height: 1.2em;
  }

  .appointment_main h3 {
    font-size: 25px;
    line-height: 1.2em;
  }
}

@media screen and (max-width: 766px) {
  .appointment_main h1 {
    font-size: 30px;
    line-height: 1.2em;
  }

  .appointment_main h3 {
    font-size: 25px;
    line-height: 1.2em;
  }

  .appointment_main p {
    font-size: 16px;
    line-height: 1.3em;
  }

  .appointment_main .apointment_taking_content .border_padding {
    padding: 6%;
  }
}

.details_form .form-control {
  border-radius: 8px;
}

.details_form .form-control:focus {
  border-color: rgb(6, 148, 130);
}

.details_form .form-label {
  font-size: 14px !important;

  font-family: "proxima-nova", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #696969;
}

.details_form .form-check-input:checked {
  background-color: rgb(6, 148, 130);
  border-color: rgb(6, 148, 130);
}

.details_form .form-check-input:focus {
  box-shadow: unset;
  border-color: rgb(6, 148, 130);
}

.details_form button {
  padding: 21px;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid rgb(6, 148, 130) !important;
  color: #fff !important;
  background: rgb(6, 148, 130) !important;
  font-weight: 700;
  border-radius: 40px;
  box-shadow: none !important;
  font-size: 15px !important;
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .appointment_main
    .react-calendar__tile.react-calendar__month-view__days__day {
    height: 62px !important;
  }
}

/* @media screen and (min-width: 426px) and (max-width: 767px) {
    .appointment_main .react-calendar__tile.react-calendar__month-view__days__day {
        height: 62px !important;
    }
}
@media screen and (min-width: 426px) and (max-width: 767px) {
    .appointment_main .react-calendar__tile.react-calendar__month-view__days__day {
        height: 62px !important;
    }
}
@media screen and (min-width: 426px) and (max-width: 767px) {
    .appointment_main .react-calendar__tile.react-calendar__month-view__days__day {
        height: 62px !important;
    }
} */

